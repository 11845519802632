<template>
    <div class="row">

        <div class="col pt-2">


            <!--  Page  -->
            <div class="bg-white rounded py-2 shadow-widget border vh-100">

                <!-- Page header -->
                <div class="px-3">

                    <div class="d-flex">

                        <h4 class="settings-categories-header">{{ $t('page.categories') }}</h4>

                        <div class="ml-auto align-self-center">

                            <b-button v-if="(getIntActive === true)" :disabled="disabled" :variant="'info'" @click="update" class="settings-categories-buttons" >
                              <font-awesome-icon v-if="!disabled" :icon="'sync-alt'" /> <b-spinner v-if="disabled" small type="grow"></b-spinner>
                              {{ $t('configuration.crm_integration')}}
                            </b-button>

                            <b-button :variant="'primary'" @click="add" class="settings-categories-buttons">
                                <font-awesome-icon :icon="'plus'" />
                                {{ $t('default.add')}}
                            </b-button>
                          <b-button :variant="'primary'" @click="importCategory" class="settings-categories-buttons">
                            <font-awesome-icon :icon="'plus'" />
                            {{ $t('simple.import') }}
                          </b-button>

                            <!-- Show only if we have category id -->
                            <template v-if="category">

                                <b-button :variant="'info'" @click="edit(category)" class="settings-categories-buttons" >
                                    <font-awesome-icon :icon="'edit'" />
                                    {{ $t('simple.edit')}}
                                </b-button>

                                <b-button :variant="'danger'" @click="remove(category)" class="settings-categories-buttons">
                                    <font-awesome-icon :icon="'trash'" />
                                    {{ $t('simple.remove')}}
                                </b-button>


                            </template>

                        </div>

                    </div>

                    <span class="text-muted font-weight-light">{{ $t('page.categories_desc') }}</span>

                </div>

                <hr>

                <!-- Page content -->
                <div class="px-2 px-md-5 pb-5">


                    <v-wait for="categories">

                        <loading slot="waiting"/>

                        <template v-if="categories">
                            
                            <treeselect

                                v-model="category"

                                :multiple="false"

                                :placeholder="$t('page.categories_select_desc')"

                                :options="categories"

                                :normalizer="normalizer"

                                :alwaysOpen="true"

                            />
                        
                        </template>


                    </v-wait>

                </div>


            </div>


        </div>

    </div>
</template>

<script>

    import CategoryAdd from './Modal/CategoryAdd.vue'
    import CategoryEdit from './Modal/CategoryEdit.vue'
    import CategoryDelete from './Modal/CategoryDelete.vue'

    import { create } from 'vue-modal-dialogs'

    import Treeselect from '@riophae/vue-treeselect'
    import {mapActions, mapGetters} from "vuex";
    import CategoryImport from "./Modal/CategoryImport.vue";


    export default {

        components: { Treeselect },


        mounted: function () {
            // Component mounted
            console.log('Category component mounted.')
        },

        destroyed() {
            // Component destroyed
            console.log('Category component destroyed.');
        },

        created: function() {
            // Component created
            this.fetchCrmIntegration();
            this.getCategories();
        },
        

        data: function() {
            return  {
                categories_api: '/api/v1/settings/category/',
                category: null,
                categories: null,
                disabled: false,
                timeout: null,
            }
        },
        
        metaInfo: function() {
            
            return {
                title: this.$t('page.categories'),
            }

        },

        computed: {

          // Map getters
          ...mapGetters({
            // Integration Active
            getIntActive: 'Integrations/getIntActive',
          }),

        },

        methods: {

          // Integration
          ...mapActions('Integrations', [
            'fetchCrmIntegration',
            'updateCrmCategory',
          ]),

            // Get Categories
            getCategories: async function(){


            console.log('buralardayım ');

                // Start waiting
                this.$wait.start('categories');

                // Get categories
                await axios.get(this.categories_api)
                  .then((response) => {

                    // Set categories
                    this.categories = response.data;
                    //console.log(response);

                    // End waiting
                    this.$wait.end('categories');

                  })
                  .catch((error) => {
                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));
                    
                    // Log to console
                    console.log(error);

                    // End waiting
                    this.$wait.end('categories');

                  });

            },

          importCategory: async function () {
            // Create and show the import modal
            const category_import = create(CategoryImport);

            // Wait for the modal to close and refresh categories if necessary
            if (await category_import()) {
              this.getCategories();
            }
          },

          normalizer: function(node){
                return {
                    id: node.id,
                    label: node.name,
                    children: node.children,
                }
            },

          // Add Category
            add: async function() {
                // Show Category add modal
                const category_add = create(CategoryAdd);

                if (await category_add() ){
                    // Refresh account list
                    this.getCategories();
                }

            },




            edit: async function() {
                // If id is empty
                if(!this.category){
                    Vue.toasted.error(this.$t('simple.records_not_found'));
                    return;
                }

                // Show customer type edit modal
                const category_edit = create(CategoryEdit, 'id');

                if (await category_edit(this.category) ){
                    // Refresh customer type list
                    // Categories list updated automatically on click
                    this.getCategories();
                }

            },

            remove: async function() {
                // If id is empty
                if(!this.category){
                    Vue.toasted.error(this.$t('simple.records_not_found'));
                    return;
                }

                // Show customer type remove modal
                const category_remove = create(CategoryDelete, 'id');

                if (await category_remove(this.category) ){
                    // Refresh Customer Type list
                    // Categories list updated automatically on click
                    this.getCategories();
                }

            },

          update: async function() {
            this.disabled = true

            if (await this.updateCrmCategory() ){
              // Refresh Customer Type list
              // Categories list updated automatically on click
              this.getCategories();
            }

            // Re-enable after 10 seconds
            this.timeout = setTimeout(() => {
              this.disabled = false
            }, 20000)

          },

        },

      beforeDestroy () {
        // Clear the timeout before the component is destroyed
        clearTimeout(this.timeout)
      }
        
    }
</script>