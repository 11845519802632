const state = {

    task: [],
    int_active: false,
    task_id: false,
    customer_tasks: [],
    task_tickets: [],
    customer_id: false,
    ticket: false,
    ticket_id: false,
    int_company_name: '',
    int_company_id: '',
    int_username: '',
    int_pass: '',
    int_api_url: '',
    crm_save_status: false,
    crm_error: '',



};

// getters
const getters = {

    // Get Customer ID
    getCustomerID: (state) => {
        // Return Tasks
        return state.customer_id;
    },

    // Get Customer Tasks
    getCustomerTasks: (state) => {
        // Return Tasks
        return state.customer_tasks;
    },

    // Get Task
    getTask: (state) => {
        // Return Task
        return state.task;
    },

    // Get Task ID
    getTaskId: (state) => {
        // Return Task Id
        return state.task.task_id;
    },

    // Get Ticket ID
    getTicketId: (state) => {
        // Return Ticket Id
        return state.ticket_id;
    },

    // Get Task's Tickets
    getTaskTicketID: (state) => {
        // Return task tickets
        return state.task.ticket_id;
    },

    // Get Task's Tickets Case ID
    getTaskTicketCase: (state) => {
        // Return task ticket's Case ID
        return state.task.case_id;
    },

    // Get Customer ID
    getTaskCustomer: (state) => {
        // Return Customer ID
        return state.task.customer_id;
    },

    // Get Task note
    getTaskNote: (state) => {
        // Return Task Note
        return state.task.note;
    },

    // Get Task Detail
    getTaskDetail: (state) => {
        // Return Task Details
        return state.task.detail;
    },

    // Get Task Status(Open-Waiting)
    getTaskStatus: (state) => {
        // Return Task Status
        return state.task.status;
    },

    // Get Task Create Date
    getTaskCreateDate: (state) => {
        // Return Int Created Date
        return state.task.created;
    },

    // Get Task Update Date
    getTaskUpdatedDate: (state) => {
        // Return Int Updated Date
        return state.task.updated;
    },

    // Get Task Response-Answer Status
    getTaskResponseStatus: (state) => {
        // Return integration answer status
        return state.task.response_status;
    },

    // Get Task Response-Answer ID
    getTaskResponse: (state) => {
        // Return Integration Answer
        return state.task.response;
    },

    // Get Task Response Created Date
    getTaskResponseDate: (state) => {
        // Return Integration Response Created Date
        return state.task.response_date;
    },

    // Get Integration System Status(Active-Passive)
    getIntActive: (state) => {
        // Return Int System Status(Active-Passive)
        return state.int_active;
    },


    // Get Integration Company Name
    getIntCompanyName: (state) => {
        // Return Int Company Name
        return state.int_company_name;
    },

    // Get Integration Company ID
    getIntCompanyId: (state) => {
        // Return Int Company Name
        return state.int_company_id;
    },

    // Get Integration Username
    getIntUsername: (state) => {
        // Return Int Username
        return state.int_username;
    },


    // Get Integration Password
    getIntPass: (state) => {
        // Return Int Pasword
        return state.int_pass;
    },

    // Get Integration Integration Api URL
    getIntApiUrl: (state) => {
        // Return Int Api URL
        return state.int_api_url;
    },

    // Get CRM save action
    getCrmSaveStatus: (state) => {
        // Return Crm Save Status
        return state.crm_save_status;
    },

    // Get CRM Error message
    getCrmError: (state) => {
        // Return Crm Save Status
        return state.crm_error;
    },


};

// mutations
const mutations = {

    // Set Customer ID
    setCustomerID(state, customer_id) {
        state.customer_id = customer_id;
    },

    // Set Customer Tasks List
    setCustomerTasks(state, customer_tasks) {
        state.customer_tasks = customer_tasks;
    },

    // Set Task Id
    setTaskId(state, task_id) {
        state.task.task_id = task_id;
    },

    // Set Task's Tickets
    setTaskTicketID(state, ticket_id) {
        state.task.ticket = ticket_id;
    },

    // Set Task's Tickets
    setTaskTicketCase(state, case_id) {
        state.task.case_id = case_id;
    },

    // Set Ticket ID
    setTicketID(state, ticket_id) {
        state.ticket_id = ticket_id;
    },

    // Set Customer ID
    setTaskCustomerID(state, task_customer_id) {
        state.task.customer_id = task_customer_id;
    },

    // Set Task Note
    setTaskNote(state, task_note) {
        state.task.note = task_note;
    },

    // Set Task Detail
    setTaskDetail(state, task_detail) {
        state.task.detail = task_detail;
    },

    // Set Task Status(Open-Waiting)
    setTaskStatus(state, task_status) {
        state.task.status = task_status;
    },

    // Set Task Create Date
    setTaskCreateDate(state, task_created) {
        state.task.created = task_created;
    },

    // Set Task Update Date
    setTaskUpdatedDate(state, task_updated) {
        state.task.updated = task_updated;
    },

    // Set Integration System Status(Active-Passive)
    setIntActive(state, int_active) {
        state.int_active = int_active;
    },

    // Set Task Response-Answer Status
    setTaskResponseStatus(state, task_response_status) {
        state.task.response_status = task_response_status;
    },

    // Set Task Response-Answer ID
    setTaskResponse(state, task_response) {
        state.task.response = task_response;
    },

    // Set Task Response Created Date
    setTaskResponseDate(state, task_response_date) {
        state.task.response_date= task_response_date;
    },

    // Set Integration Company Name
    setIntCompanyName(state, int_company_name) {
        state.int_company_name = int_company_name;
    },

    // Set Integration Company ID
    setIntCompanyId(state, int_company_id) {
        state.int_company_id = int_company_id;
    },

    // Set Integration Username
    setIntUsername(state, int_username) {
        state.int_username = int_username;
    },

    // Set Integration Password
    setIntPass(state, int_pass) {
        state.int_pass = int_pass;
    },

    // Set Integration Api URL
    setIntApiUrl(state, int_api_url) {
        state.int_api_url = int_api_url;
    },

    // Set CRM Save Status
    setCrmSaveStatus(state, crm_save_status) {
        state.crm_save_status = crm_save_status;
    },

    setIntCrmError(state, crm_error) {
        state.crm_error = crm_error;
    },

};

// actions
const actions = {



    // Fetch CRM Integration Configuration Infos
    async fetchCrmIntegration({dispatch, commit}) {

        // Start waiting
        dispatch('wait/start', 'int_conf', {root: true});

        let int_conf_api = '/api/v1/settings/configurations/';

        // Get CRM Integration Infos
        await axios.get(int_conf_api)
            .then((response) => {

                // Set Integration Mode
                commit('setIntActive', response.data.crm_status);
                // Set Integration Company Name
                commit('setIntCompanyName', response.data.crm_company_name);
                // Set Integration Company ID
                commit('setIntCompanyId', response.data.crm_company_id);
                // Set Integration Username
                commit('setIntUsername', response.data.crm_username);
                // Set Integration Pass
                commit('setIntPass', response.data.crm_password);
                // Set Integration Api URL
                commit('setIntApiUrl', response.data.crm_api_url);
                // Set Integration Crm Error
                commit('setIntCrmError', response.data.crm_error);

                // Stop waiting
                dispatch('wait/end', 'int_conf', {root: true});

            })
            .catch((error) => {
                // Show error
                Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'int_conf', {root: true});

            });

    },



    // Update CRM Integration Configuration Infos
    async updateCrmIntegration({dispatch, state}) {

        // Start waiting
        dispatch('wait/start', 'int_conf', {root: true});

        let task_api = '/api/v1/settings/configurations/';


        // Update CRM Integrations Configuration Infos
        await axios.put(task_api + 1, {

            crm_status: state.int_active,
            crm_company_name: state.int_company_name,
            crm_company_id: state.int_company_id,
            crm_username: state.int_username,
            crm_password: state.int_pass,
            crm_api_url: state.int_api_url,

        })

            .then((response) => {

                // Stop waiting
                dispatch('wait/end', 'int_conf', {root: true});

                // Show success
                Vue.toasted.success(Vue.i18n.translate('simple.success_info'));

            })
            .catch((error) => {
                // Show error
                Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'int_conf', {root: true});

            });


    },




    // Update CRM Categories from CRM
    async updateCrmCategory({dispatch}) {

        // Start waiting
        dispatch('wait/start', 'categories', {root: true});

        let crm_sync_api = '/api/v1/settings/category/crm-sync/';


        // Update CRM Categories
        await axios.post(crm_sync_api)

            .then((response) => {


                // Show success
                Vue.toasted.success(Vue.i18n.translate('simple.success_info'));

                // Stop waiting
                dispatch('wait/end', 'categories', {root: true});

            })
            .catch((error) => {
                // Show error
                Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'categories', {root: true});

            });

    },








    // Fetch Customer's Tasks with Info - Ticket ID - case_id
    async fetchCustomerTasks({dispatch, commit}, customer_id) {

        // Start waiting
        dispatch('wait/start', 'customer_tasks', {root: true});
        commit('setCustomerID', customer_id);
        let customer_task_api = '/api/v1/integration/CustomerTasks';

        // Get Customer Tasks List
        await axios.get(customer_task_api + '?customer_id=' + customer_id)
            .then((response) => {

                // Set Customer Tasks List
                commit('setCustomerTasks', response.data);

                // Stop waiting
                dispatch('wait/end', 'customer_tasks', {root: true});

            })
            .catch((error) => {
                // Show error
                Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'customer_tasks', {root: true});

            });

    },

    // Fetch Task Info
    // Ticket ID can be equal to many uniq Task ID
    // But one Task ID should have one Ticket

    async fetchTask({dispatch, commit}, ticket_id) {

        // Start waiting
        dispatch('wait/start', 'task', {root: true});
        commit('setTicketID', ticket_id);
        let get_task_api = '/api/v1/integration/task';

        // Get Task
        await axios.get(get_task_api + '?ticket_id=' + ticket_id)
            .then((response) => {


                    // Set Task Id
                    commit('setTaskId', response.data.task_id);

                    // Set Task's Tickets
                    commit('setTaskTicketID', response.data.ticket_id);

                    // Set Task's Tickets's case_id
                    commit('setTaskTicketCase', response.data.case_id);

                    // Set Customer ID
                    commit('setTaskCustomerID', response.data.customer_id);

                    // Set Task Note
                    commit('setTaskNote', response.data.note);

                    // Set Task Detail
                    commit('setTaskDetail', response.data.detail);

                    // Set Ticket District
                    commit('setTaskStatus', response.data.status);

                    // Set Task Create Date
                    commit('setTaskCreateDate', response.data.created);

                    // Set Task Update Date
                    commit('setTaskUpdatedDate', response.data.updated);

                    // Set Task Response-Answer Status
                    commit('setTaskResponseStatus', response.data.response_status);

                    // Set Task Response-Answer ID
                    commit('setTaskResponse', response.data.response);

                    // Set Task Response Created Date
                    commit('setTaskResponseDate', response.data.response_date);

                // Stop waiting
                dispatch('wait/end', 'task', {root: true});

            })
            .catch((error) => {
                // Show error
                Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'task', {root: true});

            });

    },



    updateTaskRefresh({dispatch, rootGetters}) {

        // We will update our task
        dispatch('updateTask');

    },

    // Update ticket with data(We should save which content sent with old action)
    async updateTask({dispatch, state, rootGetters}) {

        // Start waiting
        dispatch('wait/start', 'ticket', {root: true});

        let task_api = '/api/v1/inbox/integration/task';
        state.ticket = rootGetters['Ticket/getTicket'];

        // Start Task Function to record CRM
        // If There is a Task ID for Ticket we can post a NOTE(in Future)
        // If There isn't a Task ID, we can create a Task
        // Every category Should have uniq task
        await axios.put(task_api + state.ticket.id, {

            ticket_id: state.ticket.id,
            title: state.ticket.title,
            categories: state.ticket.categories,

        })

            .then((response) => {

                // Stop waiting
                dispatch('wait/end', 'ticket', {root: true});

                // Show success
                Vue.toasted.success(Vue.i18n.translate('simple.success_info'));

            })
            .catch((error) => {
                // Show error
                Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'ticket', {root: true});

            });

        // Stop waiting
        dispatch('wait/end', 'ticket', {root: true});

    },

    // Get Customer Tickets
    async fetchCustomerTickets({dispatch, commit}, customer_id) {

        // Start waiting
        dispatch('wait/start', 'customer_tickets', {root: true});

        let customer_tickets_api = '/api/v1/inbox/customertickets';
        state.customerTickets = [];
        // Get Ticket
        await axios.get(customer_tickets_api + '?customer_id=' + customer_id)
            .then((response) => {

                if (response.data.error) {
                    commit('setPermission', Vue.i18n.translate('permission.read-transfer'));
                    console.log(Vue.i18n.translate('permission.denied'));
                }

                // Assign accounts

                commit('setCustomerTickets', response.data);

                // Stop waiting
                dispatch('wait/end', 'customer_tickets', {root: true});

            })
            .catch((error) => {
                // Show error
                Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'customer_tickets', {root: true});

            });

    },


    // Get Active Categories List for Setting Page
    async getIntCategories({dispatch, commit}) {

        let int_category_api = '/api/v1/system/category';

        // Get categories
        await axios.get(int_category_api)
            .then((response) => {

                // CRM Catogory Should be updated from CRM category
                // commit('setCategories', response.data);

            })

            .catch((error) => {
                // Show error
                Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

            });

    },

    // Update CRM Save Status
    updateCrmSaveStatus({commit}, status) {
        commit('setCrmSaveStatus', status);
    },


};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};