<template>

    <div class="row">

        <div class="col pt-2">

            <!--  Page  -->
            <div class="bg-white rounded py-2 shadow-widget border">

                <!-- Page header -->
                <div class="px-3">

                    <div class="d-flex">

                        <h4>{{ $t('page.crm_configurations') }}</h4>

                        <div class="ml-auto align-self-center">


                        </div>

                    </div>

                    <span class="text-muted font-weight-light">{{ $t('page.crm_configurations_desc') }}</span>

                </div>

                <hr>

                <!-- Page content -->
                <div class="px-3">

                  <span class="mt-3"><strong>{{ $t('configuration.crm_configurations') }}</strong></span>
                  <span class="text-muted font-weight-light"> - {{ $t('configuration.crm_configurations_desc') }}</span>
                  <hr>
                  <v-wait for="int_conf">

                    <loading slot="waiting"/>
                  <b-container fluid>
                    <!-- CRM Integration Company Name -->
                    <b-row>
                      <b-col cols="6" md="3" lg="2" v-b-tooltip.hover :title="$t('configuration.crm_int_mode_desc')">{{ $t('configuration.crm_int_mode') }}</b-col>
                      <b-col >
                        <div>
                          <b-form-checkbox
                              id="checkbox-1"
                              v-model="int_mode"
                              name="checkbox-1"
                              value="true"
                              unchecked-value="false"
                          >
                            {{ $t('configuration.' + int_mode) }}
                          </b-form-checkbox>
                        </div>

                      </b-col>
                    </b-row>
                    <hr>
                    <!-- CRM Integration Company Name -->
                    <b-row>
                      <b-col sm="12" md="3" lg="2" class="mb-2" v-b-tooltip.hover :title="$t('configuration.crm_company_desc')">{{ $t('configuration.crm_company_name') }}</b-col>
                      <b-col sm="12" md="4" lg="3">
                        <div>
                          <b-form-input v-model="int_company_name" :state="validationCompany" id="company-name"></b-form-input>
                          <b-form-invalid-feedback id="company-name" :state="validationCompany">
                            {{ $t('configuration.crm_company_name_length') }}
                          </b-form-invalid-feedback>
                        </div>

                      </b-col>
                    </b-row>
                    <br>
                    <b-row>
                      <b-col sm="12" md="3" lg="2" class="mb-2" v-b-tooltip.hover :title="$t('configuration.crm_company_id_desc')">{{ $t('configuration.crm_company_id') }}</b-col>
                      <b-col sm="12" md="4" lg="3" >
                        <div>
                          <b-form-input v-model="int_company_id" :state="validationCompanyId" id="company-id"></b-form-input>
                          <b-form-invalid-feedback id="company-id" :state="validationCompanyId">
                            {{ $t('configuration.crm_company_id_length') }}
                          </b-form-invalid-feedback>
                        </div>

                      </b-col>
                    </b-row>
                    <hr>
                    <!-- CRM Integration Username and Password -->
                    <b-row>
                      <b-col sm="12" md="3" lg="2" class="mb-2" v-b-tooltip.hover :title="$t('configuration.crm_username_desc')">{{ $t('configuration.crm_username') }}</b-col>
                      <b-col sm="12" md="4" lg="3">

                        <div>
                          <b-form-input v-model="int_username" :state="validationText" id="user-name"></b-form-input>
                          <b-form-invalid-feedback id="user-name" :state="validationText">
                            {{ $t('configuration.crm_username_length') }}
                          </b-form-invalid-feedback>
                        </div>
                      </b-col>
                    </b-row>
                    <br>
                    <b-row>
                      <b-col sm="12" md="3" lg="2" class="mb-2" v-b-tooltip.hover :title="$t('configuration.crm_pass_desc')">{{ $t('configuration.crm_pass') }}</b-col>
                      <b-col sm="12" md="4" lg="3">
                        <div>
                          <b-form-input type="password" id="text-password" v-model="int_pass" :state="validationPass" aria-describedby="password-help-block"></b-form-input>
                          <b-form-invalid-feedback id="password-help-block" :state="validationPass">
                            {{ $t('configuration.crm_pass_length') }}
                          </b-form-invalid-feedback>
                        </div>
                      </b-col>
                    </b-row>
                    <hr>
                    <!-- CRM Integration Api Link -->
                    <b-row>
                      <b-col sm="12" md="3" lg="2" class="mb-2" v-b-tooltip.hover :title="$t('configuration.crm_api_url_desc')">{{ $t('configuration.crm_api_url') }}</b-col>
                      <b-col sm="12" md="4" lg="3">
                        <div>
                          <b-form-input type="url" v-model="int_api_url" id="api-url"></b-form-input>
                        </div>

                      </b-col>
                    </b-row>

                  <b-row v-if="getCrmError">
                      <h6 class="mt-4"><font-awesome-icon :icon="'exclamation-triangle'" class="plus__icon text-danger"/> {{ $t('configuration.crm_error_title') }}</h6>
                      <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-danger">{{ getCrmError }}</div>
                  </b-row>

                  </b-container>

                    </v-wait>


                </div>


                <hr>
                <!-- Page footer -->


                <div class="py-3 px-3">
                    <b-button class="px-3" variant="success" @click="updateCrmIntegration">{{ $t('default.save') }}</b-button>
                </div>


            </div>


        </div>

    </div>

</template>


<script>


    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {

        mounted: function () {
            // Component mounted
            console.log('CRM Configurations component mounted.')
        },

        destroyed() {
            // Component destroyed
            console.log('CRM Configurations component destroyed.');
        },

        created: function() {
            // Component created
           this.fetchCrmIntegration();
        },


        data: function() {
            return  {}
        },

        metaInfo: function() {

            return {
                title: this.$t('page.crm_configurations'),
            }

        },

        methods: {

            // Map actions
            ...mapActions('Integrations', [
              // Get CRM Integration Configuration Infos
                'fetchCrmIntegration',
              // Update CRM Integration Configuration Infos
                'updateCrmIntegration',
            ]),

            // Map Mutations
            ...mapMutations('Integrations', [
                'setIntActive',
                'setIntCompanyName',
                'setIntCompanyId',
                'setIntUsername',
                'setIntPass',
                'setIntApiUrl',
            ]),

        },

        computed: {

            // Map getters
            ...mapGetters({

                // Integration Mode
                getIntActive: 'Integrations/getIntActive',

                // Integration Company Name
                getIntCompanyName: 'Integrations/getIntCompanyName',

                // Integration Company Name
                getIntCompanyId: 'Integrations/getIntCompanyId',

                // Integration Username
                getIntUsername: 'Integrations/getIntUsername',

                // Integration Password
                getIntPass: 'Integrations/getIntPass',

                // Integration Api URL
                getIntApiUrl: 'Integrations/getIntApiUrl',

                getCrmError: 'Integrations/getCrmError'


            }),

            // Integration Mode
            int_mode: {

                // getter
                get: function () {
                    // Get Integration Mode
                    return this.getIntActive;
                },

                // setter
                set: function (value) {
                    // Check if we have value?
                    if (value) {
                        // Set Integrtion Mode
                        this.setIntActive(value);
                    }
                },

            },

            // CRM Integration Company Name
            int_company_name: {

                // getter
                get: function () {
                    // Get DRM Integration Company Name
                    return this.getIntCompanyName;
                },

                // setter
                set: function (value) {
                    // Check if we have value?
                    if (value) {
                        // Set CRM Integration Company Name
                        this.setIntCompanyName(value);
                    }
                },

            },

            // CRM Integration Company Name
            int_company_id: {

                // getter
                get: function () {
                    // Get DRM Integration Company Name
                    return this.getIntCompanyId;
                },

                // setter
                set: function (value) {
                    // Check if we have value?
                    if (value) {
                        // Set CRM Integration Company Name
                        this.setIntCompanyId(value);
                    }
                },

            },

            // CRM Integration Username
            int_username: {

                // getter
                get: function () {
                    // Get CRM Integration Username
                    return this.getIntUsername;
                },

                // setter
                set: function (value) {
                    // Check if we have value?
                    if (value) {
                        // Set CRM Integration Username
                        this.setIntUsername(value);
                    }
                },

            },

            // CRM Integration Password
            int_pass: {

                // getter
                get: function () {
                    // Get CRM Integration Password
                    return this.getIntPass;
                },

                // setter
                set: function (value) {
                    // Check if we have value?
                    if (value) {
                        // Set CRM Integration Password
                        this.setIntPass(value);
                    }
                },

            },

            // CRM Integration Api URL
            int_api_url: {

                // getter
                get: function () {
                    // Get CRM Integration Api URL
                    return this.getIntApiUrl;
                },

                // setter
                set: function (value) {
                    // Check if we have value?
                    if (value) {
                        // Set CRM Integration Api URL
                        this.setIntApiUrl(value);
                    }
                },

            },

            // Company Name Length Controller
            validationCompany() {
              if(this.int_company_name) {
                  return this.int_company_name.length > 4 && this.int_company_name.length < 31;
              }
            },

            // Company Id Length Controller
            validationCompanyId() {
              if(this.int_company_id) {
                  return this.int_company_id.toString().length >= 1;
              }
            },

            // Text Length Controller
            validationText() {
              if(this.int_username) {
                  return this.int_username.length > 4 && this.int_username.length < 31;
              }
            },

            // Pass Length Controller
            validationPass() {
              if(this.int_pass) {
                  return this.int_pass.length > 4 && this.int_pass.length < 19;
              }
            }


        },
        
    }
</script>