<template>
  <div class="modal fade show d-block ps--scrolling-y" tabindex="-1" role="dialog" >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('default.import_category')}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <v-wait for="import_category">
            <loading slot="waiting" />
            <div class="modal-body">
              <b-row class="align-items-center mb-3">
                <b-col sm="2">
                  <label for="campaign_audience">{{ $t('simple.file') }}<span
                      class="text-danger"></span>:</label>
                </b-col>
                <b-col sm="12" lg="4" class="mb-3">
                  <div :class="{ 'isInvalid': !file }"
                       class="media-upload-container d-flex justify-content-center align-items-center">
                    <div class="position-absolute">
                      <div class="d-flex flex-column align-items-center text-center">
                        <font-awesome-icon :icon="'folder-open'" class="text-primary" />
                        <span class="media-upload-inner-container text-muted font-weight-normal"
                              v-if="!file">{{ $t('macros.attach_file') }}</span>
                        <span class="media-upload-inner-container text-muted font-weight-normal"
                              v-else>{{ $t('macros.change_file') }}</span>
                        <span
                            class="media-upload-inner-container text-muted font-weight-normal">(CSV
                                                or Excel)</span>
                        <span class="media-upload-inner-container text-muted font-weight-normal">
                                                (Max 50MB)</span>
                      </div>
                    </div>
                    <b-form-file id="campaign_audience" v-model="file" plain
                                 :accept="accepted"
                                 @input="addFile(file, 2)"
                                 class="media-upload-button h-100 w-100">
                    </b-form-file>
                  </div>
                </b-col>
                <b-col sm="4" v-if="file" class="mb-3">
                  <div class="position-relative media-preview-container d-flex">
                    <div class="align-self-center p-1">
                      <font-awesome-icon :icon="'file'" />
                      <span>{{ file.name }}</span>
                    </div>
                    <b-btn size="sm" class="preview-remove-button position-absolute" variant="danger"
                           @click="removeFile">
                      <font-awesome-icon :icon="'times'" />
                    </b-btn>
                  </div>
                </b-col>
              </b-row>
              <b-row class="align-items-center ml-3 mb-3">
                <b-col>

                  <ul>
                    <li class="text-muted"><a href="/documents/categories.xls" download>Download
                      Sample File</a></li>
                  </ul>
                </b-col>
              </b-row>
            </div>

            <div class="modal-footer">

              <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>

              <b-button variant="success" @click.prevent="uploadAudience"
                        :disabled="!(file)">{{ $t('simple.import') }}</b-button>

            </div>
          </v-wait>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {

  mounted: function () {
  },

  destroyed() {
    console.log('Account Add Modal destroyed.');
  },
  data() {
    return {
      file: null,
      authorizationConfirmation: true,
      accepted:"text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

    }
  },
  created: function() {

  },
  methods: {
    ...mapActions('Ticket', [
      "importCategories"
    ]),
    addFile(file, limit) {
      if (file && !this.checkFileSize(file, limit)) {
        Vue.toasted.error(this.$t('macros.size_limit_alert', {
          size_limit: limit,
        }));
        this.removeFile();
      }

    },

    checkFileSize(file, size_limit) {
      if (((file.size / 1024) / 1024).toFixed(4) > size_limit) {
        return false
      }

      return true
    },

    removeFile() {
      this.file = null;
    },

    async uploadAudience() {
      if (this.file) {
        const formData = new FormData();
        formData.append('excel', this.file);

        try {
          // Attempt to import the categories
          await this.importCategories({ file: formData });

          // Show success toast
          Vue.toasted.success(this.$t('macros.import_success'));

          // Close the modal
          this.$close(true);
        } catch (error) {
          // Log the error for debugging purposes
          console.error("Error importing categories:", error);

          // Show error toast
          Vue.toasted.error(this.$t('macros.import_error'));
        }

      }

    },
  },
  computed: {
  },
}
</script>


